import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Resources = () => {
  return (
    <Layout>
      <SEO title="Fitness and Spirit- My Story" />
      <div className="container-main">
        <div className="container-main_div">
          <h1>Resources</h1>
          <p>
            &quot;I can do all things through Christ who strengthens me&quot;
            (Phil 4:13)
          </p>
          <p>
            Through my mission, I desire to share tools that have helped me and
            continue to inspire me. May you find love, wisdom, and claim your
            upgrades.
          </p>
          <ul className="pt-2">
            <li className="pt-2 text-blue-700">
              <a href="https://www.bodybuilding.com/">Bodybuilding.com</a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.jointhereboot.com">Joe Cross</a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.revelationwellness.org">
                Revelation Wellness
              </a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.fitnessmagazine.com">Fitness Magazine</a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.masterysystems.com">Mastery Systems</a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.focusonthefamily.com">Focus on the Family</a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.Faithandhealthconnection.org">
                Faith and Health Connection
              </a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.thisispain.com">This Is Pain</a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.aarda.org">
                Autoimmune Related Diseases Association
              </a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.rsds.org  ">Supporting the CRPS Community</a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.thyroid.org">American Thyroid Association</a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.fibroandpain.org">
                National Fibromyalgia and Chronic Pain Association
              </a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.bringchange2mind.org">
                Bring Change to Mind
              </a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.adaa.org">
                Anxiety and Depression Association of America
              </a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.samhsa.gov/find-help/national-helpline">
                Substance Abuse and Mental Health Services Administration
              </a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://youandmeregistry.com/">
                You + M.E.: ME/CFS Registry, support & tracking
              </a>
            </li>
            <li className="pt-2 text-blue-700">
              <a href="https://www.instagram.com/rosemariephilip.coach/">
                Instagram: Rosemariephilip.coach #endowillnotwin
              </a>
            </li>
            <li className="pt-2 text-blue-400">
              Pain Scale: app tracker & resources for device &nbsp;
              <a
                className="text-blue-700"
                href="https://apps.apple.com/us/app/painscale-pain-diary-and-coach/id1240897273?%2524fallback_url=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fpainscale-pain-diary-and-coach%2Fid1240897273%3Fmt%3D8&_branch_match_id=772582208967870485"
              >
                App Store
              </a>
              &nbsp;or&nbsp;
              <a
                className="text-blue-700"
                href="https://play.google.com/store/apps/details?id=com.bostonscientific.solace&hl=en_US&%24fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.bostonscientific.solace%26hl%3Den_US&_branch_match_id=772582208967870485"
              >
                Google Play
              </a>
            </li>
            <li className="pt-2 text-blue-400">
              My Fibro Team: app tracker & resources for device &nbsp;
              <a
                href="https://apps.apple.com/us/app/myfibroteam-mobile/id965288026?ls=1"
                className="text-blue-700"
              >
                App Store
              </a>
              &nbsp;or&nbsp;
              <a
                className="text-blue-700"
                href="https://play.google.com/store/apps/details?id=com.myhealthteams.MyFibroTeam&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                Google Play
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default Resources
